import React, { useCallback, useEffect, useState } from "react"
import Portal from "../../../Portal"
import styles from "./style.module.scss"

export default function SelectOptions({
  children,
  updateWrapperCoords,
  onClickOutside,
  triggerInfo,
  isOutOfViewport,
  wrapperStyles = {}
}) {
  const [wrapperInfo, setWrapperInfo] = useState({})

  useEffect(() => {
    window.addEventListener("resize", updateWrapperCoords)
    return () => {
      window.removeEventListener("resize", updateWrapperCoords)
    }
  }, [])

  const measuredRef = useCallback((node) => {
    if (!!node) {
      const { bottom, top, height } = node.getBoundingClientRect()
      const { innerHeight } = window
      let isOut
      if (bottom > innerHeight) {
        isOut = true
        isOutOfViewport({
          in: "bottom"
        })
      } else {
        isOut = false
        isOutOfViewport({})
      }
      setWrapperInfo({ bottom, top, height, isOut })
    }
  }, [])

  const getStyles = useCallback(() => {
    let style

    if (wrapperInfo?.isOut) {
      style = {
        left: triggerInfo.x,
        bottom: window.innerHeight - triggerInfo.top - 3,
        width: `${triggerInfo.width}px`
      }
    } else {
      style = {
        left: triggerInfo.x,
        top: triggerInfo.bottom - 3,
        width: `${triggerInfo.width}px`
      }
    }

    return style
  }, [triggerInfo, wrapperInfo])

  return (
    <Portal>
      <div className={styles.overlayBackdrop} onClick={onClickOutside} />
      <div className={styles.overlayConnectedPositionBoundingBox}>
        <div
          ref={measuredRef}
          style={{
            position: "absolute",
            ...getStyles(),
            ...wrapperStyles,
            pointerEvents: "none"
          }}
        >
          {children}
        </div>
      </div>
    </Portal>
  )
}
