import { get as getvalue } from "get-wild"
import { isValidElement } from "react"
import ReactDOMServer from "react-dom/server"

const cleanHtmlString = function (text) {
  if (typeof window === "undefined") {
    return text
  }

  const doc = new DOMParser().parseFromString(text, "text/html")

  return doc.body.textContent || ""
}

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const matchsSearchText = (searchText, el, props) => {
  if (searchText === "") {
    return true
  }

  const normalizedSearchText = removeAccents(searchText)
  const regex = new RegExp(normalizedSearchText, "i")

  let matches = false

  props.some((x) => {
    let values = getvalue(el, x)
    if (!Array.isArray(values)) {
      values = [values]
    }

    values.some((v) => {
      if (isValidElement(v)) {
        v = ReactDOMServer.renderToString(v)
      }
      if (typeof v === "object") {
        return false
      }
      v = removeAccents(cleanHtmlString(v))
      if (regex.test(v)) {
        matches = true
        return true
      }
    })

    return matches
  })

  return matches
}
