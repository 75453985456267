import cx from "classnames"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import Loading from "src/components/Loading"
import SelectAllNoneAction from "src/components/SelectAllNoneAction"
import Tooltip from "src/components/Tooltip"
import { matchsSearchText } from "src/helpers/search"
import useConfirm from "src/hooks/useConfirm"
import useTranslation from "src/hooks/useTranslation"
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect"
import FormField, { FormFieldContext } from ".."
import Button from "../../Button"
import Divider from "../../Divider"
import Icon from "../../Icon"
import CheckedOption from "./CheckedOption"
import SelectOption from "./SelectOption"
import SelectOptions from "./SelectOptions"
import styles from "./style.module.scss"

export const formatChoices = (choices, selected) => {
  return choices?.map((x) => {
    x.selected = Array.isArray(selected) ? selected.includes(x.id) : selected == x.id

    return { ...x }
  })
}

const compareArrays = (arr1, arr2) => {
  if (arr1 === null && arr2 === null) {
    return true
  }

  if ((arr1 === null && arr2 !== null) || (arr2 === null && arr1 !== null)) {
    return false
  }

  if (arr1.length != arr2.length) {
    return false
  }

  return arr1.every((element_1) =>
    arr2.some((element_2) => Object.keys(element_1).every((key) => element_1[key] === element_2[key]))
  )
}

export default function Select({
  selectionChange,
  canAdd = null,
  multiple,
  idSelected = null,
  onAdd = {
    service: () => {},
    success: () => {},
    error: () => {}
  },
  disabled = false,
  readOnly = false,
  canAddDisabled = false,
  addEmpty = false,
  showOptionSelectedBelow = false,
  optionStyle = {},
  initialOptions = [],
  selectTriggerStyle = {},
  showAboutComponent = false,
  className = null,
  optionsMaxWidth = null,
  optionsWrapperStyles = {},
  searchable = true
}) {
  const [options, setOptions] = useState(initialOptions)
  const { optionsWrapperRefContext, setIsMultipleContext } = useContext(FormFieldContext)

  const [showOptions, setShowOptions] = useState(false)
  const selectRef = useRef(null)
  const [inputValue, setInputValue] = useState("")
  const [bound, setBound] = useState({})
  const [isOutOfViewport, setIsOutOfViewport] = useState({})
  const [searchValue, setSearchValue] = useState("")

  const [optionAlreadyExist, setOptionAlreadyExist] = useState(false)

  const { t } = useTranslation()

  const { confirm: confirmDelete, Confirm: DeleteConfirm } = useConfirm()

  let disabledText = null

  if (typeof disabled == "object") {
    const f = Object.entries(disabled).find(([text, condition]) => condition)
    disabledText = f ? f[0] : null
    if (disabledText && typeof disabledText === "string") {
      disabledText = t(disabledText, disabledText)
    }
    if (disabledText) {
      disabled = true
    } else {
      disabled = false
    }
  }

  const updateOptions = (newV) => {
    const compare = compareArrays(newV, options)
    if (!compare) {
      setOptions(newV)
    }
  }

  useDeepCompareEffectNoCheck(() => {
    if (initialOptions?.length <= 0 || initialOptions == null) {
      if ((options === null && Array.isArray(initialOptions)) || (initialOptions === null && Array.isArray(options))) {
        updateOptions(initialOptions)
      }
      return
    }

    const emptyObject = {
      id: null,
      label: typeof addEmpty === "string" ? addEmpty : t("main_ui.general.filter_choice_empty_default"),
      selected: initialOptions?.filter((el) => el.selected).length <= 0
    }

    if (addEmpty !== false) {
      updateOptions([emptyObject, ...initialOptions])
    } else {
      updateOptions(initialOptions)
    }
  }, [initialOptions, addEmpty])

  const updateDropdownCoords = (button) => {
    setBound(button.getBoundingClientRect())
  }

  const onClickOptionHandler = ({ id, label }) => {
    const newOptions = [...options]

    newOptions.forEach((element) => {
      element.selected = false

      if (element.id === id) {
        element.selected = true
      }
    })

    setOptions(newOptions)

    selectionChange({ id, label })
    setShowOptions(false)
  }

  const onCheckHandler = ({ id, isChecked }) => {
    let newOptions = [...options]

    const index = newOptions.findIndex((el) => el.id === id)

    newOptions[index] = { ...newOptions[index], selected: isChecked }

    setOptions(newOptions)

    selectionChange(
      newOptions
        .filter((el) => el.selected)
        .map((el) => {
          return { id: el.id, label: el.label }
        })
    )
  }

  const addHandler = async (e) => {
    if (!inputValue.trim()) {
      setInputValue("")
      return null
    }

    let alreadyExist = options.some((el) => el.label === inputValue)

    if (alreadyExist) {
      setOptionAlreadyExist(true)
      return null
    }

    setOptionAlreadyExist(false)

    try {
      const { data } = await onAdd.service(inputValue, idSelected !== null ? idSelected : null)

      const newOption = { id: data.id, label: inputValue, data }

      let lastOptions = [...options]

      let selectionData

      let newOptions = [...lastOptions, newOption]

      if (multiple) {
        newOption.selected = true

        selectionData = newOptions
          .filter((el) => el.selected)
          .map((el) => {
            return { id: el.id, label: el.label }
          })
      } else {
        lastOptions.forEach((element) => {
          element.selected = false
        })

        newOption.selected = true

        setShowOptions(false)

        selectionData = {
          id: newOption.id,
          label: newOption.label
        }
      }
      onAdd.success(newOption)

      selectionChange(selectionData)

      setOptions(newOptions)
    } catch (error) {
      onAdd.error(error)
    } finally {
      setInputValue("")
    }
  }

  const onChangeHandler = (e) => {
    setInputValue(e.target.value)
  }

  const onSelectHandler = ({ id, label }, isChecked) => {
    if (multiple) {
      onCheckHandler({ id, label, isChecked })
    } else {
      onClickOptionHandler({ id, label })
    }
  }

  const disabledOptionStyle = {
    fontWeight: "bold",
    pointerEvents: "none",
    color: "red",
    border: "none",
    justifyContent: "center"
  }

  const renderChildren = (options) => {
    const _filteredOptions = options.filter((option) =>
      searchable ? matchsSearchText(searchValue, option, ["label"]) : true
    )

    return !!_filteredOptions.length ? (
      _filteredOptions.map((el, index) => (
        <SelectOption
          className={styles.selectOption}
          style={el?.disabled ? disabledOptionStyle : optionStyle}
          key={`${el.id}_${index}`}
          checked={multiple && el.selected}
          multiple={multiple}
          onSelect={onSelectHandler.bind(this, { id: el.id, label: el.label })}
        >
          <div>
            <div>{el.label}</div>
            {el.subtext && <div className={styles.subtext}>{el.subtext}</div>}
          </div>
        </SelectOption>
      ))
    ) : searchValue ? (
      <SelectOption style={disabledOptionStyle} key={"empty-option"}>
        {t("main_ui.components.dropdownlist.lb_search_nomatches")}
      </SelectOption>
    ) : null
  }

  useEffect(() => {
    setIsMultipleContext(true)
  }, [multiple, setIsMultipleContext])

  const triggerHandler = (_) => {
    if (disabled || readOnly) {
      return null
    }
    updateDropdownCoords(selectRef.current)
    setShowOptions(!showOptions)
  }

  const getBorderClass = useMemo(() => {
    let borderClass

    if (Object.entries(isOutOfViewport).length > 0) {
      if (isOutOfViewport.in === "bottom") {
        borderClass = styles.dropdownBottom
      }
    } else {
      borderClass = styles.dropdownTop
    }

    return borderClass
  }, [isOutOfViewport])

  const classNameTrigger = cx(
    styles.selectTrigger,
    disabled && styles.disabled,
    readOnly && styles.readOnly,
    multiple && styles.multiple,
    showOptionSelectedBelow && styles.showOptionSelectedBelow
  )

  const optionsCheckedTrue = () => {
    const checked = options.filter((option) => option.selected)

    if (checked.length > 1) {
      return <div style={{ marginTop: 3, marginLeft: 3 }}>{checked.length} Seleccionados</div>
    } else {
      return checked.map((elem) => {
        return (
          <CheckedOption key={elem.id} showAboutComponent={showAboutComponent}>
            {elem.id !== null ? elem.label : ""}
          </CheckedOption>
        )
      })
    }
  }

  const handleClick = (elem) => {
    confirmDelete("¿Desea remover este elemento?", {
      componentType: "YesNo",
      confirmButtonText: "Sí, Quitar"
    }).then((r) => {
      if (r) {
        onSelectHandler(elem, false)
      }
    })
  }

  const handleSelectAll = (checked) => {
    setOptions((prev) => {
      return prev.map((x) => {
        x.selected = checked
        return x
      })
    })

    selectionChange(
      checked
        ? options.map((x) => {
            return { id: x.id, label: x.label }
          })
        : []
    )
  }

  if (options === null) {
    return (
      <div style={{ minHeight: 24, display: "flex", alignItems: "center" }}>
        <Loading type={"PulseLoader"} height={18} width={48} size={10} centered={false} />
      </div>
    )
  }

  return (
    <>
      <Tooltip
        content={disabledText}
        disabled={disabledText === null}
        title={t("main_ui.general.lb_disabled_option_title")}
        className='disabled-op'
        size='small'
      >
        <div
          style={selectTriggerStyle}
          className={cx(className, "selectTrigger", classNameTrigger, options?.length == 0 && styles.empty)}
          ref={selectRef}
          onClick={triggerHandler}
        >
          <div
            className={styles.selectValue}
            title={!multiple && !showOptionSelectedBelow && options?.find((el) => el.selected)?.label}
          >
            {multiple
              ? showAboutComponent && optionsCheckedTrue()
              : showOptionSelectedBelow
              ? null
              : options?.find((el) => el.selected)?.label}
          </div>
          {!readOnly && (
            <div className={styles.selectArrowWrapper}>
              <Icon name='keyboard_arrow_down' color='#808080' />
            </div>
          )}
        </div>
      </Tooltip>
      {showOptions && (
        <SelectOptions
          isOutOfViewport={(obj) => {
            setIsOutOfViewport(obj)
          }}
          onClickOutside={() => {
            setShowOptions(false)
          }}
          triggerInfo={bound}
          updateWrapperCoords={() => updateDropdownCoords(selectRef.current)}
          wrapperStyles={{ ...{ maxWidth: optionsMaxWidth, minWidth: "fit-content" }, ...optionsWrapperStyles }}
        >
          <div className={`${styles.dropdown} ${getBorderClass}`}>
            <div className={styles.dropdownContent}>{renderChildren(options)}</div>
            {searchable && options.length > 5 && (
              <FormField border={false}>
                <input
                  autoFocus
                  placeholder={t("main_ui.general.lb_search_placeholder")}
                  type='text'
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </FormField>
            )}

            {multiple && options?.length > 1 && (
              <div style={{ padding: "2px 5px" }}>
                <SelectAllNoneAction size={"mini"} onSelectAll={handleSelectAll} />
              </div>
            )}
            {canAdd && (
              <>
                <Divider style={{ marginTop: 10 }} />
                <div className={styles.addWrapper}>
                  <div>
                    <FormField>
                      <input
                        placeholder={canAdd.placeholder || ""}
                        type='text'
                        value={inputValue}
                        onChange={onChangeHandler}
                      />
                    </FormField>
                    {optionAlreadyExist && <span style={{ color: "#ed4138" }}>Nombre ya existe</span>}
                  </div>

                  <Button
                    style={{ width: "fit-content" }}
                    text={canAdd.text || "Agregar"}
                    name='normal'
                    disabled={canAddDisabled}
                    type='big'
                    option='normal'
                    handleClick={addHandler}
                  />
                </div>
              </>
            )}
          </div>
        </SelectOptions>
      )}
      {multiple &&
        optionsWrapperRefContext &&
        !!options &&
        !showAboutComponent &&
        createPortal(
          <>
            {options
              .filter((el) => el.selected)
              .map((elem) => (
                <CheckedOption
                  className={cx(styles.checkedOption, disabled && styles.disabled, readOnly && styles.readOnly)}
                  key={elem.id}
                >
                  {elem.id !== null ? elem.label : ""}
                  {!disabled && !readOnly && (
                    <a onClick={() => handleClick(elem)} className={styles.deleteIcon}>
                      <i className='ui icon fitted small delete' />
                    </a>
                  )}
                </CheckedOption>
              ))}

            {!disabled && !readOnly && <DeleteConfirm />}
          </>,
          optionsWrapperRefContext
        )}
      {!multiple &&
        showOptionSelectedBelow &&
        optionsWrapperRefContext &&
        !!options &&
        createPortal(
          <CheckedOption className={cx(disabled && styles.disabled, readOnly && styles.readOnly)}>
            {options?.find((el) => el.selected)?.id !== null ? options?.find((el) => el.selected)?.label : ""}
          </CheckedOption>,
          optionsWrapperRefContext
        )}
    </>
  )
}
