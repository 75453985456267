import cx from "classnames"
import useTranslation from "src/hooks/useTranslation"
import styles from "./styles.module.scss"

export default function SelectAllNoneAction({ onSelectAll, size = null, className = null }) {
  const { t } = useTranslation()

  return (
    <div className={cx("ui horizontal list", styles.list, size, className)}>
      <div className='item'>
        <a className={cx("ui tertiary button blue compact", size)} onClick={() => onSelectAll(true)}>
          {t("main_ui.general.btn_select_all")}
        </a>
      </div>
      <span className='item'>|</span>
      <div className='item'>
        <a className={cx("ui tertiary button blue compact", size)} onClick={() => onSelectAll(false)}>
          {t("main_ui.general.btn_select_none")}
        </a>
      </div>
    </div>
  )
}
