import cx from "classnames"
import { useEffect, useState } from "react"
import styles from "./style.module.scss"

export default function SelectOption({
  children,
  checked,
  multiple,
  onSelect,
  style,
  idSelected,
  disabled,
  className = null
}) {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const onClickHandler = () => {
    if (multiple) {
      setIsChecked((last) => !last)
      onSelect(!isChecked)
    } else {
      onSelect()
    }
  }

  return (
    <div style={{ ...style }} className={cx(styles.option, className)} onClick={onClickHandler}>
      {multiple ? <div className={`${styles.pseudoCheckbox} ${isChecked && styles.checked}`} /> : null}
      {children}
    </div>
  )
}
