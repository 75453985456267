import cx from "classnames"
import React, { useReducer } from "react"
import { Confirm } from "semantic-ui-react"
import YesNoModal from "src/macrocomponents/YesNoModal"

const reducer = (state = { open: false, props: {} }, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        open: true,
        props: { ...state.props, ...action.payload }
      }

    case "HIDE":
      return {
        open: false
      }
  }
}

let resolveCallback
function useConfirm() {
  const [confirmState, dispatch] = useReducer(reducer, { open: false, props: {} })

  const onConfirm = () => {
    closeConfirm()
    resolveCallback(true)
  }

  const onCancel = () => {
    closeConfirm()
    resolveCallback(false)
  }

  const closeConfirm = () => {
    dispatch({ type: "HIDE" })
  }

  const buildContent = (text) => {
    if (React.isValidElement(text)) {
      return text
    }
    if (typeof text === "string") {
      text = { text }
    }

    return (
      <div className='ui basic segment' style={{ display: "flex", ...text.style }}>
        <i
          className={cx(
            text.iconSize ?? "massive",
            text.iconColor ?? "orange",
            text.icon ?? "exclamation triangle",
            "icon"
          )}
        />

        <div className='content' style={{ flexGrow: 1 }}>
          {text.header && <div className='ui header'>{text.header}</div>}
          {React.isValidElement(text.text) ? text.text : <div dangerouslySetInnerHTML={{ __html: text.text }}></div>}
        </div>
      </div>
    )
  }

  const confirm = (
    text,
    options = {
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonsInverted: false,
      size: "small",
      header: null,
      componentType: null
    }
  ) => {
    options.confirmButton = {
      content: options.confirmButtonText ?? "Aceptar"
    }
    delete options.confirmButtonText

    options.cancelButton = {
      content: options.cancelButtonText ?? "Cancelar",
      negative: true
    }
    delete options.cancelButtonText

    dispatch({
      type: "SHOW",
      payload: {
        ...options,
        content: options.componentType == "YesNo" ? text : buildContent(text),
        onConfirm,
        onCancel
      }
    })

    return new Promise((res) => {
      resolveCallback = res
    })
  }

  const ConfirmComponent = ({ type = null, invertedButtons = null }) => {
    if (!confirmState.open) {
      return null
    }
    if (!type) {
      type = confirmState.props?.componentType
    }
    if (invertedButtons === null) {
      invertedButtons = confirmState.props?.confirmButtonsInverted
    }

    if (type == "YesNo") {
      return (
        <YesNoModal
          isOpen
          onClose={confirmState.props.onCancel}
          config={{
            title: confirmState.props.header,
            question: confirmState.props.content,
            buttons: {
              left: {
                text: confirmState.props.cancelButton.content,
                name: invertedButtons ?? false ? "warning" : "normal"
              },
              right: {
                text: confirmState.props.confirmButton.content,
                name: invertedButtons ?? false ? "normal" : "warning"
              }
            }
          }}
          onSuccess={() => {
            confirmState.props.onConfirm()
          }}
          className={confirmState.props.className}
          style={confirmState.props.style}
        />
      )
    }

    return <Confirm open {...confirmState.props} />
  }

  return { confirm, Confirm: ConfirmComponent, onConfirm, onCancel, confirmState }
}

export default useConfirm
